<template>
  <div>
    <b-card>
      <h6 class="mb-4 font-weight-bold">Filter</h6>
      <b-row class="p-3">
      <b-col cols="3">
            <b-form-group
            label="Year"
          >
          <custom-select
              v-model="year"
              placeholder="Select year"
              :options="yearOption"
            />
        </b-form-group>
        </b-col>
      <b-col cols="6">
        <b-button
          class="merge reset mr-2 mt-4"
          variant="outline-warning"
          @click="resetFilter"
        >
          Reset Filter
        </b-button>
        <b-button
          class="next merge mr-3 mt-4"
          variant="warning"
          @click="fetchDataTable"
        >
          Apply Filter
        </b-button>
      </b-col>
    </b-row>
    </b-card>
     <div class="d-flex view-project">
    <div class="container-view left p-4 mr-4">
      <h5 class="mb-4 font-weight-bold">Profit Summary {{ year }}</h5>
      <b-row>
        <b-col>
          <div class="text-muted">Project Amount Total</div>
          <div class="font-weight-bold mt-2" :style="{ color : '#2F80ED' }">{{ formatMoney(totalAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</div>
        </b-col>
      </b-row>
      <hr/>
      <b-row>
        <b-col>
          <div class="text-muted">Cost Total</div>
          <div class="font-weight-bold mb-4 mt-2" :style="{ color : '#F7AC26' }">{{ formatMoney(totalCost, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</div>
        </b-col>
      </b-row>
      <hr/>
      <b-row>
        <b-col>
          <div class="text-muted">Profit Total</div>
          <div class="font-weight-bold mb-4 mt-2" :style="{ color : '#4CAF50' }">{{ formatMoney(totalProfit, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</div>
        </b-col>
      </b-row>
    </div>
    <div class="container-view right p-4">
      <div class="d-flex justify-content-between">
      <h5 class="font-weight-bold mb-3">Data Table</h5>
            <b-form-group class="m-0">
        <b-form-input
          v-model="search"
          placeholder="Search..."
          size="sm"
          @change="changeFilter"
        ></b-form-input>
      </b-form-group>
      </div>
    <br/>
    <b-row v-show="!items.length">
      <b-col>
       <div align="center">
       <img src="@/assets/icon-no-invoice.png"/>
      </div>
      </b-col>
    </b-row>
    <b-table
    v-show="items.length"
    :items="items"
    :fields="fields"
    :per-page="perPage"
    :current-page="currentPage"
    :sort-by.sync="sortBy"
    striped
    responsive>

      <template #cell(no)="{ item, index }">
      {{ index + 1 }}
      </template>

      <template #cell(projectAmount)="{ item }">
        {{ formatMoney(item.projectAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
      </template>

      <template #cell(projectCost)="{ item }">
        {{ formatMoney(item.projectCost, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
      </template>

      <template #cell(projectProfit)="{ item }">
        {{ formatMoney(item.projectProfit, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
      </template>

      </b-table>
      <div class="d-flex justify-content-end align-items-center">
        <span class="show-entries mr-auto ml-4">
          {{`Show ${totalRows === 0 ? 0 : ((currentPage-1) * perPage) + 1} to ${totalRows > ((currentPage) * perPage) ? ((currentPage) * perPage) : totalRows} of ${totalRows} entries`}}
        </span >
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          pills
          hide-goto-end-buttons
          @input="fetchDataTable"
        />
      </div>
      </div>
    </div>
   </div>
</template>

<script>
import api from '@/api'
import { formatMoney } from 'accounting-js'
import moment from 'moment'

export default {
  components: {
  },
  computed: {},

  data: () => ({
    yearOption: [],
    year: moment().format('YYYY'),
    items: [],
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    totalPage: 0,
    totalAmount: 0,
    totalCost: 0,
    totalProfit: 0,
    search: '',
    sortBy: 'name',
    fields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'name', label: 'Projects', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'periode', label: 'Periode', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'projectAmount', label: 'Project Amount', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'projectCost', label: 'Cost', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'projectProfit', label: 'Profit', tdClass: 'text-center', thClass: 'text-center', sortable: true }
    ]
  }),

  created() {
    this.fetchDataTable()
    this.fetchYear()
  },

  methods: {
    formatMoney,
    async fetchYear() {
      const { data } = await api.summaryProfit.profitYear()
      this.yearOption = data.data
    },
    changeFilter() {
      this.filters = 'name@=*' + this.search
      this.fetchDataTable()
    },
    resetFilter() {
      this.year = moment().format('YYYY')
      this.fetchDataTable()
    },
    async fetchDataTable() {
      const { data } = await api.summaryProfit.list({
        page: this.currentPage,
        pageSize: this.perPage,
        filters: this.filters,
        sorts: this.sortBy,
        year: this.year
      })
      this.items = data.profitProjectDetail.length !== 0 ? data.profitProjectDetail.data : []
      this.totalRows = data.profitProjectDetail.length !== 0 ? data.profitProjectDetail.totalData : 0
      this.totalPage = data.profitProjectDetail.totalPage
      this.totalAmount = data.totalAmount
      this.totalCost = data.totalCost
      this.totalProfit = data.totalProfit
      this.items.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.view-project {
  background: #F5F6F9;
}

.container-view {
  background: #fff;
  display: flex;
  flex-direction: column;

  &.left {
    width: 25vw;
  }

  &.right {
    width: 64vw;
  }
}

.status {
  background: rgba(214, 35, 174, 0.2);;
  color: #D623AE;
}
</style>
